<template>
  <div class="terms-container">
    <LandingPageHeader />

    <div class="main-content">
      <h3 class="text-header-black">Terms and Conditions of Use</h3>
      <p>Last updated: 23/11/2021</p>
      <h5 class="text-header-black">1. Introduction</h5>
      <p>
        Welcome to matrimonyassist.com! By using or accessing
        matrimonyassist.com site (hence the "<u>Site</u> www.
        matrimonyassist.com”), you acknowledge that you are subject to the
        following terms and conditions (the "<u>Terms</u>"). You should read
        through the Terms carefully; they constitute a legally binding
        agreement. You are contracting with matrimonyassist.com. ("<u>we,</u>"
        or "<u>us</u>"). If you do not fully agree to these Terms, you may not
        access or otherwise use the Site.
      </p>
      <p>
        These terms and conditions govern your access to and use of this website
        and any other ‘matrimonyassist.com’ branded or controlled website or
        section of a website (including, without limitation, a
        matrimonyassist.com Facebook page, Twitter account, LinkedIn page) (the
        "Website") and any ' matrimonyassist.com’ branded applications (the
        "Apps"), as updated from time to time.
      </p>
      <h5>2. Information about us</h5>
      <p>
        The website www.matrimonyassist.com is a site operated by HMS Global
        Business Limited, to which the words "we", "us" and "our" refer. We are
        registered in England and Wales under company number 07212488. We own
        and operate a series of global websites including this site. If you have
        questions regarding these Terms of Use, please contact us via the Help
        Centre page.
      </p>
      <h5>3. Accessing our site</h5>
      <p>
        Access to our site is permitted on a temporary basis, and we reserve the
        right to withdraw or amend the service that we provide on our site
        without notice. We will not be liable if for any reason our site is
        unavailable at any time or for any period.
      </p>
      <p>
        You are responsible for making all arrangements necessary for you to
        have access to our site. You are also responsible for ensuring that all
        persons who access our site through your internet connection are aware
        of these terms, and that they comply with them.
      </p>
      <h5>4. Restrictions and unauthorised activities</h5>
      <p>
        You may use our site only for lawful purposes. Unless otherwise provided
        within these Terms, or unless otherwise applicable law requires us to
        allow you to do so, you may not use our site:
      </p>
<p>
  <ul style="list-style-type: disc"> 
        <li>in any way that breaches any applicable law or regulation;</li>
        <li>
          in any way that is unlawful or fraudulent, or has any unlawful or
          fraudulent purpose or effect;
        </li>
        <li>
          for the purpose of harming or attempting to harm anyone in any way;
        </li>
        <li>
          (other than with our prior express written permission) for any
          commercial or trading purpose, or for any use other than private use;
        </li>
        <li>
          to transmit, or procure the sending of, any unsolicited or
          unauthorised advertising or promotional material or any form of
          similar solicitation (spam);
        </li>
        <li>in contravention of any of these Terms of Use.</li>
      </ul>
</p>
      <h5>5. Intellectual property rights</h5>
      <p>
        We are the owner or the licensee of all intellectual property rights in
        our site, and in the material published on it. Those works are protected
        by copyright laws and treaties around the world. All such rights are
        expressly reserved.
      </p>
      <p>
        You may print off one copy, and may download extracts, of any page(s)
        from our site for your personal and private reference, and you may draw
        the attention of others within your organisation to material posted on
        our site. If you choose to do this, you must always expressly
        acknowledge our status (and that of any identified contributors) as the
        authors of materials.
      </p>
      <p>
        You must not modify the paper or digital copies of any materials that
        you have printed off or downloaded in any way, and you must not use any
        illustrations, photographs, video or audio sequences or any graphics
        separately from any accompanying text.
      </p>
      <p>
        If you print off, copy or download any part of our site in breach of
        these terms of use, your right to use our site will cease immediately
        and you must, at our option, return or destroy any copies of the
        materials you have made.
      </p>
      <p>
        The composite word-and-device "matrimonyassist.com" logo, and each
        element of it, is trademarks owned and used by us.
      </p>
      <h5>6. Changes to Site and/or Unavailability of Site</h5>
      <p>
        We may change, suspend or discontinue any aspect of the Site at any
        time, including the availability of any Site features, database, or
        content, where we have legal, commercial or technical reasons to do so.
        We may also impose limits on certain features or services or restrict
        your access to parts or the entire Site for similar reasons.
      </p>
      <p>
        Also there are times when the Site may become inaccessible as a result
        of technical difficulties experienced by us or on the Internet. We will,
        however, use reasonable skill and care to overcome these difficulties
        where they are within our control. Please note, in those situations, we
        cannot guarantee continuous access to the Site or any of its specific
        features.
      </p>
      <h5>7. Our liability</h5>

      <p>
        To the extent permitted by law, we, other members of our group of
        companies and third parties connected to us hereby expressly exclude:
      </p>
      <p>
        all conditions, warranties and other terms which might otherwise be
        implied by statute, common law or the law of equity, whether relating to
        the accuracy of any material displayed on our site or to the
        accessibility of our site or otherwise howsoever;
      </p>
      <p>
        any liability for any direct, indirect or consequential loss or damage
        incurred by any user in connection with our site or in connection with
        the use, inability to use, or results of the use of our site, any
        websites linked to it and any commentaries or other materials posted on
        it (which, for the avoidance of doubt, are not intended to constitute
        advice on which any reliance should be placed), including without
        limitation any liability for:
      </p>

     <p><ul style="list-style-type: disc"> 
        <li>loss of income or revenue;</li>
        <li>loss of business;</li>
        <li>loss of profits or contracts;</li>
        <li>loss of anticipated savings;</li>
        <li>loss of data;</li>
        <li>loss of goodwill;</li>
        <li>
          wasted management or office time; and for any other loss or damage of
          any kind, however arising and whether caused by tort (including
          negligence), breach of contract or otherwise, even if foreseeable,
          provided that this condition shall not prevent claims for loss of or
          damage to your tangible property or any other claims for direct
          financial loss that are not excluded by any of the categories set out
          above.
        </li>
      </ul></p>
      <p>
        This does not affect our liability for death or personal injury arising
        from our negligence, or our liability for fraudulent misrepresentation
        or misrepresentation as to a fundamental matter, or any other liability
        which cannot be excluded or limited under applicable law.
      </p>

      <h5>8. Information about you and your visits to our site</h5>

      <p>
        We process information about you in accordance with our privacy and
        cookie policy. By using our site, you consent to such processing, and
        you warrant that all data provided by you is accurate.
      </p>
      <p>9. Viruses, hacking and other offences</p>
      <p>
        You must not misuse our site by knowingly introducing viruses, trojans,
        worms, logic bombs or other material which is malicious or
        technologically harmful. You must not attempt to gain unauthorised
        access to our site, the server on which our site is stored, or any
        server, computer or database connected to our site. You must not attack
        our site via a denial-of-service attack or a distributed denial-of
        service attack.
      </p>
      <p>
        By breaching any of these provisions, you would or may commit a criminal
        offence under the Computer Misuse Act 1990. We will report any such
        breach to the relevant law enforcement authorities, and we will
        co-operate with those authorities by disclosing your identity to them.
        In the event of such a breach, your right to use our site will cease
        immediately.
      </p>
      <p>
        We will not be liable for any loss or damage caused by a distributed
        denial-of-service attack, virus or other technologically harmful
        material that may infect your computer equipment, computer programs,
        data or other proprietary material due to your use of our site or to
        your downloading of any material posted on it, or on any website linked
        to it.
      </p>

      <h5>10. Linking to our site</h5>
      <p>
        You may link to our site, provided you do so in a way that is fair and
        legal and does not damage our reputation or take advantage of it, but
        you must not establish a link in such a way as to suggest any form of
        association, approval or endorsement on our part where none exists.
      </p>
      <P
        >You must not establish a link to the home page of our site from any
        website that is not owned by you.</P
      >
      <P
        >Our site must not be framed on any other site, nor may you create a
        link to any part of our site other than the home page. We reserve the
        right to withdraw linking permission without notice. The website from
        which you are linking must comply in all respects with the standards set
        out in these Terms of Use.</P
      >
      <P
        >If you wish to make any use of material on our site other than that set
        out above, please address your request via the help centre page.
      </P>
      <h5>11. Links from our site</h5>
      <p>
        Where our site contains links to other sites and resources provided by
        third parties, these links are provided for your information only. We
        have no control over the contents of those sites or resources and accept
        no responsibility for them or for any loss or damage that may arise from
        your use of them.
      </p>
      <h5>12. Responsibility for user-contributed content</h5>
      <p>
        We have no duty to pre-screen content posted on the Site by members, or
        other users (including, without limitation, reviews of or guest book
        entries for any particular rental property), (collectively,
        "user-contributed content"), and we are not responsible for
        user-contributed content. We do, however, reserve the right to decline
        to permit the posting on the Site of, or to remove from, any
        user-contributed content that fails to meet our content requirements. We
        may also remove user-contributed content if it is brought to our
        attention, by a user or by a third party, or any other requirements
        governing the posting of such content, has/have been apparently breached
        in respect of that user-contributed content.
      </p>
      <h5>13. Disclaimer</h5>
      <p>
        We warrant that the Site will be provided with reasonable care and skill
        with the intention of meeting our technical specifications for the Site,
        but we cannot and do not guarantee that the Site will meet your
        requirements, including (without limitation) as to the availability or
        speed of delivery of the Site, any of its features of content, any
        communication through or relating to the Site, or any transaction you
        may enter into with other users. Except as expressly stated elsewhere in
        these Terms, all representations, warranties, conditions and other terms
        relating to the Site or any of its content, whether express or implied
        (by common law, statute, collaterally or otherwise) are hereby excluded,
        except in the case of our fraud or our fraudulent misrepresentation, or
        where such exclusion is not permitted by law. We do not warrant that the
        functions of the Site or any materials or content contained therein will
        be uninterrupted or error free, that defects will be corrected, or that
        the Site or the server that makes it available is free of viruses or
        other harmful components, although we will take reasonable steps to
        avoid ourselves introducing viruses or other harmful components to the
        Site.
      </p>
      <h5>14. Jurisdiction and applicable law</h5>
      <p>
        The English courts will have non-exclusive jurisdiction over any claim
        arising from, or related to, a visit to our site, although we retain the
        right to bring proceedings against you for breach of these Terms of Use
        in your country of residence or any other relevant country.
      </p>

      <p>
        These Terms of Use and any dispute or claim arising out of or in
        connection with them or their subject matter or formation (including
        non-contractual disputes or claims) shall be governed by and construed
        in accordance with the law of England and Wales.
      </p>
      <h5>15. Variations</h5>
      <p>
        We may revise these Terms and Conditions of Use at any time by amending
        this page. You are expected to check this page from time to time to take
        notice of any changes we made, as they are binding on you. Some of the
        provisions contained in these Terms of Use may also be superseded by
        provisions or notices published elsewhere on our site.
      </p>

      <h5>16. Your concerns</h5>
      <p>
        If you have any concerns about material which appears on our site,
        please contact us.
      </p>
      <h5>17. Support and suggestions</h5>
      <p>
        If you require further information about matrimonyassist.com or have any
        suggestions concerning how to improve the Website or Apps, please
        contact us via the help centre page.
      </p>
      <p>
        Thank you for reading our Terms and Conditions and visiting our site.
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/auth/Footer.vue";
import LandingPageHeader from "@/components/landing-page/LandingPageHeader.vue";
export default {
  name: "TermsAndCondition",
  components: {
    LandingPageHeader,
    Footer,
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/base/_variables.scss";

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  max-width: 1100px;
  margin:10px auto;
  padding: 0 10px;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 0 20px;
  }
}
</style>
